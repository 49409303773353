<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_inventory_loss_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					
					<exportModal class="ui-exportBrn" style="margin-right: 16px;" v-permission="['pc_inventory_loss_export']" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_inventory_loss_apply']" type="primary" @click="onApply">申请下账</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="资产编号" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input-number style="width: 200px" placeholder="请输入资产编号数字部分" v-model:value="formModal.startCode"/>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input-number style="width: 200px" placeholder="请输入资产编号数字部分" v-model:value="formModal.endCode"/>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="价值区间" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input-number style="width: 150px" placeholder="请输入价值" v-model:value="formModal.startPrice"/>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input-number style="width: 150px" placeholder="请输入价值" v-model:value="formModal.endPrice"/>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="资产名称" class="ui-form__item">
						<a-input placeholder="请输入资产名称" v-model:value="formModal.name"></a-input>
					</a-form-item>
					<a-form-item label="资产分类" class="ui-form__item">
						<a-cascader v-model:value="formModal.typeId" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
						<!-- <a-select v-model:value="formModal.typeId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select> -->
					</a-form-item>
					<a-form-item label="盘点任务名称" class="ui-form__item">
						<a-input placeholder="请输入盘点任务名称" v-model:value="formModal.taskName"></a-input>
					</a-form-item>
					<a-form-item label="使用部门" class="ui-form__item">
							<!-- <a-select-option :value="-1">全部</a-select-option> -->
							<a-select placeholder="请选择使用部门" v-model:value="formModal.depId" allow-clear style="width: 190px;">
								<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
							</a-select>
					</a-form-item>
					<a-form-item label="使用人" class="ui-form__item">
						<!-- <a-input v-model:value="formModal.userId"></a-input> -->
						<a-select  placeholder="请选择使用部门" v-model:value="formModal.userId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.nickname }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="是否已下账" class="ui-form__item">
						<a-select  placeholder="请选择是否已下账" v-model:value="formModal.isAffirm" allow-clear style="width: 190px;">
							<!-- <a-select-option :value="-1">全部</a-select-option> -->
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'taskName'">盘点任务名称</a-checkbox>
							<a-checkbox :value="'code'">资产编号</a-checkbox>
							<a-checkbox :value="'assetsName'">资产名称</a-checkbox>
							<a-checkbox :value="'amount'">价值</a-checkbox>
							<a-checkbox :value="'state'">是否已下账</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="index"  @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" v-if="record.status == 1" @click="onshowDetail(record)">下账申请</a-button>
							<a-button type="link" size="small" v-if="record.status == 0" @click="onshowDetail(record)">申请下账</a-button>
						</template>
						<template v-if="column.key === 'status'">
							{{ record.status == 0 ? '否' : '是' }}
						</template>
					</template>
				</c-Table>
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ index + 1 }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" @click="onshowDetail(record)">下账申请</a-button>
						</template>
						<template v-if="column.key === 'status'">
							{{ record.status == 0 ? '否' : '是' }}
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<lossApplyModal ref="lossApplyModalRef" @refresh="onReset"></lossApplyModal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import lossApplyModal from '@/views/inventory/components/lossApplyModal.vue';
	import { getLossList } from '@/service/modules/inventory.js';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, lossApplyModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				searchData: {},
				formModal: {},
				list: [],
				getDataFun: getLossList,
				type: 'inventoryLossStrategy', // 导出类型
				role: 0, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key:'index'
				}, {
					title: '盘点任务名称',
					align: 'center',
					dataIndex:'taskName'
				}, {
					title: '资产分类',
					align: 'center',
					dataIndex:'classifyName'
				}, {
					title: '资产编号',
					align: 'center',
					dataIndex:'code'
				}, {
					title: '资产名称',
					align: 'center',
					dataIndex:'assetsName'
				}, {
					title: '价值',
					align: 'center',
					dataIndex:'amount'
				}, {
					title: '使用部门',
					align: 'center',
					dataIndex:'departmentName'
				}, {
					title: '使用人',
					align: 'center',
					dataIndex:'user'
				}, {
					title: '存在地',
					align: 'center',
					dataIndex:'place'
				}, {
					title: '盘点人',
					align: 'center',
					dataIndex:'inventoryUserName'
				}, {
					title: '是否已下账',
					align: 'center',
					dataIndex:'status',
					key:'status'
				}, {
					title: '下账申请人',
					align: 'center',
					dataIndex:'createUser'
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		created(){
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getLossList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		})
			// 		this.loading = false;
			// 		if(ret.code === 200){
			// 			this.list = ret.data.list;
			// 		}
			// 	} catch (error) {
			// 		this.loading = false;
			// 	}
			// },
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				this.searchData.typeId = this.searchData.typeId ? this.searchData.typeId[this.searchData.typeId.length - 1] : undefined;
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.onSearch();
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onApply() {
				this.$refs.lossApplyModalRef.open();
			},
			onshowDetail(item) {
				// console.log("lossItem",item)
				this.$refs.lossApplyModalRef.open(item,'apply');
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		padding-left: 5px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>